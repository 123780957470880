@import "src/css/variables.scss";

table.ml-table-alt-rows {
    width: 100%;
    color: #333333;
    font-size: 1rem;
    background: #ffffff;
    margin: 0px;
    border: 1px solid $mlWhite;
    caption {
        caption-side: top;
        font-family: $mlHeaderFont;
        font-size: 1rem;
        font-weight: bold;
        color: #ffffff;
        background: #007ABC;
        text-align: center;
    }
    thead {
        tr {
            background-color: $bgColor;
        }
    }
    tr {
        text-align: center;
        height: 37px;
        &.even{
            background-color: $bgColor;
        }
        &.odd{
            background-color: $mlWhite;
        }
        td, th{
            text-align: left;
            padding: 20px;

            &.last {
                text-align: right;
            }
        }
    }
}
