@import "src/css/variables.scss";
@import "src/css/extends.scss";

@mixin ml-link {
    text-decoration: none;
    color: $mlDarkBlue;
    font-weight: bold;
    cursor: pointer;

    &:hover{
        text-decoration: none;
    }
}

@mixin ml-msg {
    .ml-msg-container {
        width: 100%;

        .fa{
            background-image: url('/img/icons/info.svg');
            background-repeat: no-repeat;
            background-size: contain;
            float: left;
            height: 21px;
            width: 21px;
            margin-top: 4px;
        }

        .ml-msg {
            min-height: 37px;
            line-height: 1.4;
            padding: 8px 15px 0;
            color: $mlDarkestGray;
            margin-bottom: 5px;

            .ml-msg-txt {
                display: inline-block;
                margin-left: 15px;
                margin-right: 10px;
            }
        }

        .ml-msg-msg {
            background: #D8EEF9;
        }
    }
}

@mixin card-title {
    font-family: $mlHeaderFont;
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 0.75rem;
    color: $mlDarkBlue;
}

@mixin header-title {
    h1 {
        font-size: 2.5rem;
        line-height: 3rem;
        margin: 1rem 0 1.5rem;
        font-family: $mlHeaderFont;

        sup {
            font-size: 1rem;
            vertical-align: baseline;
        }
    }

    p {
        font-family: $mlBodyFont;
        font-size: 1rem;
        line-height: 1.313rem;
        margin-bottom: 0.75rem;

        &:last-of-type {
            margin-bottom: 2rem;
        }
    }
}

@mixin header-title-md {
    h1 {
        font-size: 2.5rem;
        line-height: 3rem;
        margin: 2.375rem 0 1.5rem;

        sup {
            font-size: 1rem;
        }
    }
}

@mixin header-title-xs {
    h1 {
        font-size: 2.5rem;
        line-height: 3rem;

        sup {
            font-size: 1rem;
        }
    }
}

@mixin page-section {
    h2,
    h3 {
        color: $mlDarkFont;
        font-family: $mlHeaderFont;
        font-weight: 600;
        margin: 1.25rem 0;
        text-align: center;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    p {
        font-size: 0.875rem;
    }
}

@mixin page-section-md {
    @include page-section();

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    p {
        font-size: 1rem;
    }
}

@mixin icon-only-element($icon, $size, $valign) {
    background-image: url($icon);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: $size;
    vertical-align: $valign;
    width: $size;
}

// Internet Explorer 10+ only
@mixin ie-only() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
