@charset "UTF-8";
@import 'src/css/variables.scss';

.btn-outline-primary {
    color: $mlDarkBlue !important;
    border-color: $mlBlue4 !important;
    border-radius: 6px !important;

    &.btn-lg {
        border-width: 2px;
    }

    &:hover {
        color: $white !important;
        background-color: $mlDarkBlue3 !important;
        border-color: $mlDarkBlue3 !important;
    }

    &:focus {
        color: $white !important;
        background-color: $mlDarkBlue3 !important;
        outline-offset: 2px !important;
        outline: 1px solid $mlFocusOutline !important;
    }
}

.ml-button-primary,
.ml-button-primary-dark,
.ml-button-secondary,
.ml-button-secondary-dark {
    box-sizing: content-box;
    font-family: $mlBodyFont;
    font-size: 1.125rem;
    background-color: transparent;
    text-decoration: none;
    font-weight: bold;
    border-radius: 7px;
    padding: 12px 14px;
    border: 0;
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

.ml-button-primary:focus,
.ml-button-primary-dark:focus,
.ml-button-secondary:focus,
.ml-button-secondary-dark:focus,
.focus.ml-button-primary,
.focus.ml-button-primary-dark,
.focus.ml-button-secondary,
.focus.ml-button-secondary-dark {
    box-sizing: content-box;
    outline-offset: 3px;
    outline: 1px solid $mlFocusOutline;
}

.focus.ml-button-primary,
.focus.ml-button-primary-dark,
.focus.ml-button-secondary,
.focus.ml-button-secondary-dark {
    box-shadow: 0 0 3px #333, 0 0 7px #333;
}

.ml-link,
.ml-link-dark {
    text-decoration: none;
    color: $mlDarkBlue;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;

    >a {
        color: $mlDarkBlue;

        &:visited {
            color: $mlDarkBlue;
        }
    }

    a:not([href]):not([tabindex]) {
        color: $mlDarkBlue;
        text-decoration: underline;
    }
}

a.ml-link:not([href]):not([tabindex]) {
    color: $mlDarkBlue;
    text-decoration: underline;
}

a.ml-link:not([href]):not([tabindex]):hover {
    color: $mlDarkBlue;
    text-decoration: underline;
}



.ml-link:hover,
.ml-link-dark:hover,
.ml-link:focus,
.ml-link-dark:focus,
.hover.ml-link,
.hover.ml-link-dark {
    text-decoration: none;
}

.ml-link:focus,
.ml-link-dark:focus {
    outline-color: #333;
    text-decoration: none;
}

.focus.ml-link,
.focus.ml-link-dark {
    box-shadow: 0 0 4px #333, 0 0 10px #333;
}

.ml-button-primary {
    font-size: 1.125rem;
    background-color: $mlDarkBlue;
    border-radius: 7px;
    border: solid 2px transparent;
    color: #fff;
}

a.ml-button-primary:hover {
    color: #fff;
}

a.ml-button-primary:not([href]):not([tabindex]) {
    color: #fff;
}

.ml-button-primary:hover,
.ml-button-primary:focus,
.ml-button-primary.hover,
.ml-button-primary.focus {
    background-color: $mlDarkBlue3;
}

.ml-button-primary-dark {
    color: #000;
    background-color: #a4ce4e;
}

.ml-button-primary-dark:hover,
.ml-button-primary-dark:focus,
.ml-button-primary-dark.hover {
    background-color: #B7DD58;
}

.ml-button-primary-dark.focus {
    background-color: #B7DD58;
}

.ml-button-secondary {
    background-color: #fff;
    color: $mlBlue4;
    border: solid 2px $mlBlue4;
}

.ml-button-secondary:focus,
.ml-button-secondary:hover,
.ml-button-secondary.hover,
.ml-button-secondary.focus {
    background-color: $mlDarkBlue3;
    color: #fff;
}

.ml-button-secondary-dark {
    border: solid 2px #fff;
    color: #fff;
}

.ml-button-secondary-dark:focus,
.ml-button-secondary-dark:hover,
.ml-button-secondary-dark.hover,
.ml-button-secondary-dark.focus {
    background-color: #fff;
    color: $mlDarkBlue;
}

.ml-link-dark {
    color: #fff;
}

.ml-button-disabled {
    box-sizing: content-box;
    font-family: $mlBodyFont;
    font-size: 14px;
    text-transform: uppercase;
    background-color: transparent;
    text-decoration: none;
    font-weight: bold;
    border-radius: 2px;
    padding: 12px 14px;
    border: 0;
    display: inline-block;
}

.ml-button-disabled,
.ml-button-disabled:hover,
.ml-button-disabled:focus,
.focus.ml-button-disabled {
    background-color: #D9D9D9;
    color: #333;
    cursor: not-allowed;
}

.breadcrumb-item {
    text-decoration: none;
}
