@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "src/css/variables.scss";
@import "src/css/mixins.scss";

.error_page {
    .row {
        margin-right: 0px;
        margin-left: 0px;
    }
}

.error-container {
    img {
        height: 100px;
    }
}

@media screen and (max-width: 577px) {
    .error_page {
        .container {
            padding-right: 0px;
            padding-left: 0px;
        }
    }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {
    .error-container {
        padding-top: 10rem;
        padding-bottom: 19.5rem;
        img {
            height: 100px;
        }
    }
}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
