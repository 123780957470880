@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "src/css/variables.scss";
@import 'src/css/extends.scss';
@import "src/css/mixins.scss";
@import "src/css/metlife_css/tables.scss";

$ebWhite: #fff;

* {
    &:focus {
        outline: 0;
        border-radius: 2px;
        box-shadow: 0 0 0 0.15rem $mlFocusBorder;
    }
}

body {
    background-color: $ebWhite;
    color: $black;
    font-family: $mlBodyFont;
    font-size: 16px;
    margin: 0;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    font-family: $mlBodyFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        color: $mlDarkBlue;
        text-decoration: none;
    }

    h1 {
        font-family: $mlHeaderFont;
        font-size: 3.125rem;
        line-height: 3.125rem;
        margin-bottom: 10px;
    }

    h2 {
        font-family: $mlHeaderFont;
        font-size: 1.875rem;
        line-height: 1.875rem;
    }

    h3 {
        font-family: $mlHeaderFont;
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    h4 {
        font-size: 1.313rem;
        line-height: 1.5rem
    }

    h5{
        font-size: 0.875rem;
        line-height: 1.125rem
    }

    sup {
        font-size: 37.5%;
        top: -1.3em;
    }

    .app {
        height: 100vh;
        -webkit-overflow-scrolling: touch;

        .app-main-content-container {
            padding-top: 5rem;

            &.impersonated {
                padding-top: 7rem;
            }

            .sticky-top {
                background-color: $mlWhite;
                top: 7.5rem;
                border-bottom: 1px solid $mlDarkBlue;
            }

            .main-container-row {
                margin-left: auto;
                margin-right: auto;
                max-width: $mlHomeRowMaxWidth;
            }

            @include ie-only() {
                .sticky-top{
                    position: relative;
                    top: 0 !important;
                }
            }
        }

        .content-container{
            img {
                max-width: 100%;
            }
        }
        @include ml-msg;
    }
}

img.froala-content {
    cursor: default !important;
}

.appcontainer {
    padding: 0;
}

.appHeader_section {
    background: $ebWhite;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

.main-container {
    width: 100%;
    max-width: 100%;

    .container {
        width: 100%;
        max-width: 100%;
    }

    .main-container-row {
        margin-left: auto;
        margin-right: auto;
        max-width: $mlHomeRowMaxWidth;
    }
}

.the-print-button-container {
    display: none;

    .the-print-button {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 35px;
        font-size: 15px;
        float: right;

        .the-print-button_text {
            display: inline-block;
            padding-bottom: 13px;
            vertical-align: middle;

            &:hover {
                text-decoration: underline;
            }
        }

        span {
            background: url("/img/utility_print.svg") bottom;
            width: 20px;
            height: 20px;
            display: inline-block;
            background-size: 20px;
            background-position: 0 0;
            margin-right: 6px;
        }

        &:hover {
            span {
                // background: url("/img/utility_print_hover.svg") bottom;
                width: 20px;
                height: 20px;
                display: inline-block;
                background-size: 20px;
                background-position: 0 0;
            }
        }
    }
}

.row-full-width {
    position: relative;
    width: 100vw !important;
    margin-left: -50vw !important;
    left: 50%;
    margin-top: 3rem;
    padding-top: 3rem;
}

.bg-color {
    background-color: $mlLightBlue;
}

.welcome-container-left {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.welcome-container-right {
    margin-top: 10rem;
    background-color: $mlLightBlue;

    h3 {
        padding-top: 1rem;
    }
}

.skip-link {
    color: $mlWhite;
    background-color: $mlGreen2;
    height: 2.8rem;
    padding: 0.8rem;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index: 1031;
    font-size: 0.9rem;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    opacity: 0;

    &:hover {
        color: $mlWhite;
    }

    &:focus {
        transform: translateY(0%);
        opacity: 1;
    }
}

.alert-archived {
    color: #4DA9A0;
}

@media screen and (max-width: 1032px) {
    .fixedFloatingHeader_section_isFixed {
        margin-top: -70px;
    }

    .appHeader_section {
        div {
            div.container {
                margin: 0;
                max-width: none;
                padding: 0;
            }
        }
    }
}

.fixedFloatingHeader_section_isFixed {
    position: fixed;
    z-index: 9;
    background: $ebWhite;
    top: 120px;
    transition: 0.1s all;
}

@media screen and (max-width: 1032px) {
    .fixedFloatingHeader_section_isFixed {
        top: 127px;
    }
}

@include media-breakpoint-up(xs) {
    .row-full-width {
        margin-top: 1rem;
        padding-top: 1rem;
    }
}

@include media-breakpoint-up(lg) {
    body {
        .app {
            .app-main-content-container {
                padding-top: 7.5rem;
                min-height: 50vh;
                &.impersonated {
                    padding-top: 9.5rem;
                }
            }

            .the-print-button-container {
                display: block;
            }
        }
    }
}

.welcome-container-left {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.welcome-container-right {
    margin-top: 10rem;
    background-color: #E2F1F9;

    h2 {
        padding-top: 0.5rem;
    }

    a{
        text-decoration: none;
    }

    a.btn {
        color: $mlBlue2;
        border-color: $mlBlue2;
        font-size: 14px;
        font-weight: bold;
        line-height: 2;

        &:hover {
            color: $white;
            background-color: $mlBlue2;
            border-color: $mlBlue2;
        }
    }
}

.loader-container {
    background: url(/img/foremost-loader.gif) no-repeat center;
    background-size: cover;
    margin: auto;
    width: 96px;
    height: 96px;
}

.external-link:after {
    content: "";
    width: 14px;
    background-image: url(/img/icons/new-window-blue2.svg);
    display: inline-block;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 0.2rem;
}

.external-link.white-icon:after {
    content: "";
    width: 0.8rem;
    background-image: url(/img/icons/new-window-white.svg);
    display: inline-block;
    height: 0.8rem;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 0.2rem;
}

// EqualWeb accessibility
#INDmenuBtnzWrap .INDmenuRow {
    display:none !important;
}

#freeMsgText {
    transform : scale(0,0);
}

#INDquickAccess li:nth-child(1), #INDquickAccess li:nth-child(2) {
    display:none !important;
}

.INDmenuFooter div:nth-child(2) {
    display:none !important;
}

// screen reader accessibility
.visually-hidden {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
    clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
    clip-path: inset(50%); /* modern browsers, clip-path works inwards from each corner */
    white-space: nowrap;
}

.lighter {
    color: $mlDarkGray3 !important;
    font-weight: normal !important;
}

#salesforce_data {
    display: none;
}

.innerpage-banner {
    min-height: 84px;
    background-image: url('/img/rediscover/innerpage_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .content {
        color: $mlWhite;
        font-weight: bold;
    }

    .my-auto, .bottom-part {
        padding: 0 26px;
    }

    .innerpage-logo-container {
        width: 78%;
        margin-top: 25px;
    }
    .innerpage-logo-col {
        width: 44%;
        padding-top: 15px;
        float: left;
        img {
            height: 28px;
        }
    }
    .content-col {
        width: 52%;
        height: 75px;
        padding: 0;
        float: left;
    }
    .countdown-col {
        width: 22%;
        padding: 0 26px 0 0;
        text-align: right;
    }

    .overflow-col {
        width: 68%;
    }

    .actions-col {
        width: 32%;
        text-align: right;
    }

    #countdown_timer {
        min-width: 215px;
        margin-top: 36px;
        margin-right: 10px;
        font-size: 44px;
        line-height: 0.6;
        font-weight: 450;
        color: $mlWhite;

        .timer {
            width: 100%;
            display: inline-block;
            span {
                display: inline-block;
                width: 54px;
                &.colon {
                    width: 18px;
                }
            }
        }

        .hints {
            font-size: 10px;
            color: $mlWhite;
            width: 100%;
            display: inline-block;
            padding-right: 2px;
        }

        .colon {
            color: $mlDarkGray3;
        }
    }

    .close-btn {
        position: absolute;
        top: 0px;
        right: 7px;
        color: $mlWhite;
        border-color: $mlWhite;

        &:hover, &:focus {
            color: #fff;
            border-color: $mlDarkBlue;
        }
    }

    hr {
        display: block;
        height: 1px;
        border: 0;
        opacity: 0.3;
        border-top: 1px solid $mlWhite;
        margin: 4px 0;
    }

    .banner-btn {
        border-radius: 5px;
        font-size: 16px;
        border-color: $mlWhite;
        background-color: $mlDarkBlue3;
        color: $mlWhite;
        margin: 14px 0 14px 16px;

        &:hover, &:focus {
            color: $mlDarkBlue3;
            background-color: $mlWhite;
            border-color: $mlWhite;
        }
    }

    .banner-btn-quote {
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 16px;
        border-color: $mlWhite;
        background-color: $mlWhite;
        color: $mlDarkBlue3;

        &:hover, &:focus {
            color: $mlWhite;
            background-color: $mlDarkBlue3;
            border-color: $mlDarkBlue;
        }
    }

    #overlap_message {
        color: $mlWhite;
        font-weight: bold;
    }
}

@media screen and (max-width: 1090px) {
    .innerpage-banner {
        .overflow-col {
            width: 65%;
        }
        .actions-col {
            width: 35%;
        }
    }
}

@media screen and (max-width: 1032px) {
    .innerpage-banner {
        min-height: 84px;
        // background-image: url('/img/rediscover/innerpage_mobile_bg.jpg');
        .overflow-col {
            width: 60%;
        }
        .actions-col {
            width: 40%;
        }
    }
}

@media screen and (max-width: 991px) {
    .innerpage-banner {
        .innerpage-logo-container {
            width: 75%;
        }
        .innerpage-logo-col {
            width: 335px;
            img {
                height: 26px;
            }
        }
        .content-col {
            width: 46%;
        }
        .countdown-col {
            width: 25%;
            padding: 0 26px 0 0;
        }
        .overflow-col {
            width: 55%;
        }
        .actions-col {
            width: 45%;
        }
    }
}

@media screen and (max-width: 736px) {
    .innerpage-banner {
        .overflow-col {
            width: 70%;
        }
        .actions-col {
            width: 30%;
            .banner-btn-quote {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 856px) {
    .innerpage-banner {
        .innerpage-logo-container {
            width: 65%;
        }
        .content-col {
            width: 100%;
            height: auto;
            padding: 15px 0 0 25px;
        }
        .countdown-col {
            width: 35%;
        }
    }
}

@media screen and (max-width: 670px) {
    .innerpage-banner {
        .innerpage-logo-container {
            width: 100%;
        }
        .content-col {
            width: 100%;
            padding: 15px 25px 0;

        }
        .countdown-col {
            width: 100%;
            padding: 0 26px;
            text-align: center;
            #countdown_timer {
                margin-top: 28px !important;
                padding: 0 !important;
            }
        }
        .overflow-col {
            width: 100%;
            padding-top: 5px;
            #overlap_message {
                margin-bottom: 0 !important;
            }
        }
        .actions-col {
            width: 100%;
            padding-bottom: 15px !important;
            text-align: center;
            .banner-btn-quote {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .innerpage-banner {
        min-height: 84px;
        // background-image: url('/img/rediscover/innerpage_mobile_bg.jpg');
        .banner-btn, .banner-btn-quote {
            padding: 8px !important;
        }
    }
}
